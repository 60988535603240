import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import Layout from "../components/layout";
import SEO from "../components/seo";



const BackgroundSection = ({ className, children }) => {

  const { heroimage } = useStaticQuery(
    graphql`
      query {
        heroimage: file(relativePath: { eq: "coaching-page-angela-conney-color.jpg"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `

  )
  // Set ImageData.
  const imageData = heroimage.childImageSharp.fluid

  return (
      <BackgroundImage
        Tag="div"
        className={className}
        fluid={imageData}
      >
        {children}
      </BackgroundImage>
  )
}

const QuestionAndAnswer = ({question, question_num, children}) => {
  return (
<div className="group outline-none accordion-section" tabIndex={question_num}>
  <div
  className="group bg-teal-900 flex justify-between px-4 py-3 items-center text-white transition ease duration-500 cursor-pointer pr-10 relative border-b-2 border-r-0"
  >
    <div className="group-focus:text-teal-800 transition ease duration-500 ">
      <h3 className="text-3xl font-heading">{question}</h3>
    </div>
    <div
    className="h-10 w-10 border border-white rounded-full items-center inline-flex justify-center transform transition ease duration-500 group-focus:text-dark-teal group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2"
    >

    <svg className="h-12 w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
    </svg>
    </div>
  </div>
  <div
  className="group-focus:max-h-screen max-h-0 bg-teal-700 px-4 overflow-hidden ease duration-500"
  >
  <p className="p-2 text-white text-justify">
  {children}
  </p>
  </div>
</div>

  )
}

const Coaching = () => {

  return (
    <Layout headerClass="relative bg-white" bodyClass="px-0 md:px-0 lg:px-0">
      <SEO title="Coaching Q &nbsp; A" />
      <div
            className="min-h-screen h-full flex flex-col"
          >
        <BackgroundSection className="w-full h-40 sm:h-32 lg:h-56">
        <div className="container mx-auto px-4 sm:px-2 md:px-20 lg:px-24 mb-24">
          <div className="w-7/12 mt-10 sm:mt-12 lg:mt-20 font-heading self-start">
            <h1 className="text-4xl md:text-3xl lg:text-6xl text-pink-600 leading-tight bg-blac">Coaching Q/A</h1>
          </div>
        </div>
        </BackgroundSection>
        <div className="container mx-auto px-6 md:px-10 lg:px-16 mb-5"> 
          <div className="mx-auto px-2 mb-6 lg:mb-12 sm:mb-6 md:mb-6">
            <div className="w-full mt-10 ">
                <h2 className="mb-2 text-4xl font-heading text-teal-900">
                  Introduction
                </h2>
                <p className="text-base text-teal-900">
                  I compiled this Q / A which I find helpful for my clients to understand the process of coaching.
                 </p>
              </div>  
          </div>
        </div>
        <div className="container mx-auto px-6 md:px-10 lg:px-16 mb-5 "> 
          <div className="mx-auto">
            <div className="w-full ">
          <QuestionAndAnswer question="What is Executive Coaching?" question_num="1">
              
          
          Coaching helps us see things differently, from a fresh perspective, which leads to a change in our thinking and ultimately, changes in our behaviour.  It essentially focuses on increasing effectiveness, performance and personal development.  Coaching isn't about 'telling' but about facilitating the process to enable individuals and teams to find their own unique way forward, to reach their potential by gaining clarity on their goals and what does success of these goals look like.  The process inevitably encourages open and honest dialogue, that challenges existing beliefs and behaviours and reflects on fixed patterns or mindsets that no longer support these goals.
            
          </QuestionAndAnswer>
          <QuestionAndAnswer question="Who would benefit from Coaching?" question_num="2">
              
          In my experiences, as both coach and client, everyone can benefit from coaching.  The power and impact of coaching comes from its solution focused approach, where you hold agency for your decisions and outcomes.  The most immediate benefit is to help clarify meaningful value-based goals and identify next steps in relation to achieving these.  The key benefit I experienced is increased self-awareness as to how my values and beliefs align to my career and I’ve certainly experienced enhanced levels of personal responsibility, self-confidence and self-reliance.
              
          </QuestionAndAnswer>
          <QuestionAndAnswer question="Why is Coaching more relevant now?" question_num="3">
              
          On an individual level, Coaching is an effective way to process and understand our experiences and behaviours while reflecting on our perspectives, patterns and mindsets.  At the broader organisational level, coaching helps integrate change and can focus on nurturing leadership talent in high potential individuals or teams.  In challenging uncertain times, people reflect on their purpose and professional coaching can help provide a safe environment to discuss and explore what this means to us and how it aligns with our goals.                
            </QuestionAndAnswer>
                <QuestionAndAnswer question="Can coaching be completed remotely?" question_num="5">
              
                Coaching works extremely well in virtual settings. Remote sessions don’t immediately allow for the same real time connection as a face to face session, but by allowing additional time to build rapport at the start of sessions, the benefits are just as impactful.  As with all coaching sessions, I recommend a generous placeholder for your diary, to allow time to reflect afterwards without jumping to the next meeting.  It is important that both coach and client are committed to participating so I always suggest turning off distracting notifications but the key is to relax, be open and allow the session to flow.
                    
                </QuestionAndAnswer>
              <QuestionAndAnswer question="So what does the coaching process involve?" question_num="7">
              Usually one to one coaching involves a number of sessions and is kicked off with a brief introductory call to discuss openly what the process entails, what are your expectations and agree how to work together, followed by the initial coaching session which can range from one to two hours or as mutually agreed.
              </QuestionAndAnswer>
            </div>
          </div>
        </div>
        
          
      </div>

    </Layout>
   
  );
};

export default Coaching;
